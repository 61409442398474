import { parseVersionFile } from "./version";

const env = {
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || "dev"
}

export default {
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_Nm8iv6DIz",
    APP_CLIENT_ID: "4on17gsdf2gjj8a9c0qevmb06m",
    IDENTITY_POOL_ID: "eu-west-2:7e501972-9ad7-44c2-9d7c-75762528f4e2"
  },
  apis: {
    abbott: {
      baseUrl: `https://abbott.api.${env.ENVIRONMENT}.mynhsdata.uk/`,
      oauthInitUrl: "/auth_url",
      vendorStatus: "/vendor_status",
      callbackUri: "/callback",
      apiStatus: "/status",
      revokeTokens: "/revoke",
    },
    dexcom: {
      baseUrl: `https://dexcom.api.${env.ENVIRONMENT}.mynhsdata.uk/`,
      oauthInitUrl: "/auth_url",
      vendorStatus: "/vendor_status",
      callbackUri: "/callback",
      apiStatus: "/status",
      revokeTokens: "",
    },
    public: {
      baseUrl: `https://public-patient.api.${env.ENVIRONMENT}.mynhsdata.uk/api/v1`,
      statusUrl: '/status',
      patientStatus: '/patients/status',
      patientVendors: '/patients/vendors',
    },
    vendorAuth: {
      baseUrl: `https://vendor-auth.api.${env.ENVIRONMENT}.mynhsdata.uk/api/v1/`,
      statusUrl: '/status',
      // URI on the vendor-auth-api to call to continue oauth dance and get the OAuth token
      callbackUri: "/vendor/callback",
    },
    data: {
      baseUrl: "http://localhost:4000"
    },
    vendorData: {
      baseUrl: `https://vendor-data.api.${env.ENVIRONMENT}.mynhsdata.uk/api/v1/`,
      statusUrl: '/status',
    }
  },
  version: parseVersionFile(),
  debug: true,
  patientStatuses: {
    accountVerified: "account_verified",
    vendorAuthComplete: "vendor_authentication_complete",
    consentWithdrawn: "consent_withdrawn"
  },
}
