import React, {useEffect, useState} from 'react';
import {Redirect} from "react-router-dom";
import {AuthenticationState, getAuthState, handleLogout} from "../../services/HitpLogin";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Logout() {
  const [authState, setAuthState] = useState(getAuthState());
  const [errorPresent, setErrorPresent] = useState(false)

  useEffect(() => {
    const performLogout = async () => {
      try {
        await handleLogout();
        setAuthState(getAuthState())
      } catch (error) {
        setErrorPresent(true)
        console.log(error)
      }
    };
    performLogout();
  });

  if (authState === AuthenticationState.None) {
    return <Redirect to="/login"/>;
  }

  if (errorPresent) {
    return (
      <div className={"container row fill"}>
        <div className={"col-sm-10 col-md-8 col-lg-6 col-xl-4 offset-sm-1 offset-md-2 offset-lg-3 offset-xl-4 pt-5 text-center"}>
          <h1>Error</h1>
          <p>Failed to logout</p>
          <a href={"/logout"}>Try again</a>
        </div>
      </div>
    )
  }

  return (
    <div className={"container row fill"}>
      <div className={"col-sm-10 col-md-8 col-lg-6 col-xl-4 offset-sm-1 offset-md-2 offset-lg-3 offset-xl-4 pt-5 text-center"}>
        <FontAwesomeIcon className="loading-icon" icon={faSpinner} size={"2x"} spin/>
        <h1>Logging out</h1>
      </div>
    </div>
  );
};
