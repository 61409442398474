import React, {useEffect, useState} from "react";
import "./Home.scss";
import {AppPropsType} from "../../App";
import {getVendorApi} from "../../services/VendorAPI";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import {AxiosError, AxiosResponse} from "axios";
import {Route} from "react-router";
import {useMobile} from "../../libs/HooksLib";
import publicApi from "../../services/PublicAPI";
import {VendorKeys} from "../../constants";
import VendorTable from "./VendorTable";

const supportedVendors = [
    VendorKeys.Abbott,
    VendorKeys.Dexcom
]

export default function Home(props: AppPropsType) {
    const [vendors, setVendors] = useState([])
    const [vendorStatus, setVendorStatus] = useState({
        [VendorKeys.Abbott]: "",
        [VendorKeys.Dexcom]: "",
    })
    const [response, setResponse] = useState<AxiosResponse>();
    const [error, setError] = useState<AxiosError>();
    const [redirectUrl, setRedirectUrl] = useState("");
    const [modalHeader, setModalHeader] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [modalProcessingMessage, setModalProcessingMessage] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [activeVendor, setActiveVendor] = useState("");
    const [processingModalResponse, setProcessingModalResponse] = useState(false);

    useEffect(() => {
        fetchVendorStatuses()
    }, [vendors])

    useEffect(() => {
        filterVendors()
    }, [])

    useEffect(() => {
        if (!modalIsOpen) {
            fetchVendorStatuses();
        }
    }, [modalIsOpen])

    function getVendorStatus(vendor: string) {
        return vendorStatus[vendor as keyof VendorKeys]
    }

    function updateVendorStatus(vendor: string, status: string) {
        setVendorStatus(prev => ({...prev, [vendor]: status}))
    }

    async function filterVendors() {
        await publicApi.getPatientVendors()
            .then(response => {
                setVendors(response.data)
            })
    }

    async function fetchVendorStatuses() {
        setError(undefined);
        for (let vendor of vendors) {
            if (supportedVendors.includes(vendor)) {
                await getVendorApi(vendor).getVendorStatus()
                    .then((data) => {
                        updateVendorStatus(vendor, data.connected ? "Connected" : "Not connected")
                    })
                    .catch(err => {
                        console.log(err);
                        setError(err);
                    })
            }
        }
    }

    function vendorRedirect() {
        return redirectUrl &&
            <Route component={() => {
                window.location.assign(redirectUrl);
                return null;
            }}/>
    }

    async function vendorOauthInitUrl() {
        setResponse(undefined);
        setError(undefined);
        setProcessingModalResponse(true);
        await getVendorApi(activeVendor).getVendorOauthInitUrl()
            .then((res) => {
                setRedirectUrl(res.auth_url);
            })
            .catch((err: AxiosError) => {
                console.log(err);
                setError(err);
                closeConsentModal();
                if (err.response) {
                    setResponse(err.response);
                }
            })
    }

    async function vendorRevokeTokens() {
        setResponse(undefined);
        setError(undefined);
        setProcessingModalResponse(true);
        await getVendorApi(activeVendor).revokeTokens()
            .catch((err: AxiosError) => {
                console.log(err);
                setError(err);
                if (err.response) {
                    setResponse(err.response);
                }
            })
        updateVendorStatus(activeVendor, "")
        closeConsentModal();
    }

    function errorContent() {
        return error &&
            <div>
                <h3>Error info:</h3>
                <pre>{JSON.stringify(error!.toJSON(), null, 2)}</pre>
            </div>;
    }

    function responseContent() {
        return response?.data &&
            <div>
                <h3>Response content:</h3>
                <dl>
                    {Object.keys(response.data).map(key => {
                        return <>
                            <dt>{key}</dt>
                            <dd>{response.data[key]}</dd>
                        </>;
                    })}
                </dl>
            </div>;
    }

    function openGiveConsentModal(vendor: string) {
        let vendorName = vendor.charAt(0) + vendor.toLowerCase().slice(1);
        setModalHeader("Consent Required");
        setModalMessage(`By clicking 'OK' you consent to the third-party vendor ${vendorName} sharing data with NHS GG&C`);
        setModalProcessingMessage("Loading vendor site");
        setActiveVendor(vendor);
        setModalIsOpen(true);
    }

    function openRevokeConsentModal(vendor: string) {
        let vendorName = vendor.charAt(0) + vendor.toLowerCase().slice(1);
        setModalHeader("Withdraw Consent");
        setModalMessage(`By clicking 'OK' you will prevent the third-party vendor ${vendorName} from sharing data with NHS GG&C`);
        setModalProcessingMessage("Withdrawing consent");
        setActiveVendor(vendor);
        setModalIsOpen(true);
    }

    function closeConsentModal() {
        setModalIsOpen(false);
        setProcessingModalResponse(false);
    }

    function handleOkClick() {
        if (getVendorStatus(activeVendor) === "Connected") {
            vendorRevokeTokens()
        }
        if (getVendorStatus(activeVendor) === "Not connected") {
            vendorOauthInitUrl()
        }
    }

    const mobile = useMobile();

    return (
        <div id="home-page">
            <h2>My Data Sources</h2>
            <div className={"description"}>Share data from your medical devices with your clinical team.</div>
            {vendors.length ?
                VendorTable(
                    vendors,
                    getVendorStatus,
                    openGiveConsentModal,
                    openRevokeConsentModal,
                    mobile,
                ) :
                <div>
                    <FontAwesomeIcon className="loading-icon" icon={faSpinner} size={"2x"} spin/>
                    Fetching associated vendors
                </div>
            }

            {responseContent()}
            {errorContent()}
            {vendorRedirect()}

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeConsentModal}
                contentLabel="Consent"
                className={mobile ? "mobile-consent-modal" : "consent-modal"}
                overlayClassName="ConsentModalOverlay"
                ariaHideApp={false}
                id={"consent-modal"}
            >
                {!processingModalResponse ? (
                    <div className={"container fill d-flex flex-column modal-body justify-content-between"}>
                        <div className={"container"}>
                            <h2>{modalHeader}</h2>
                            <div>
                                {modalMessage}
                            </div>
                        </div>
                        <div className={"row justify-content-between button-row"}>
                            <div className={"col-sm-4 col-xs-12"}>
                                <button className={"secondary-button"} onClick={closeConsentModal}>Cancel</button>
                            </div>
                            <div className={"col-sm-4 col-xs-12"}>
                                <button className={"primary-button"} onClick={handleOkClick}>OK</button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={"modal-body fill d-flex justify-content-center align-items-center flex-column"}>
                        <FontAwesomeIcon className="loading-icon" icon={faSpinner} size={"2x"} spin/>
                        <div>{modalProcessingMessage}</div>
                    </div>
                )}
            </Modal>
        </div>
    );
}
