import React from "react";
import "./Terms.scss";

export default function Terms() {

  return (<div>
    <h1>Terms and Conditions</h1>
    <p>Last updated: June 2024</p>
    <p>Please read these terms and conditions carefully before using Our Service.</p>
    <h1>Introduction</h1>
    <p>Welcome to mynhsdata.uk. By accessing our website, you agree to comply with and be bound by the following
      terms and conditions.</p>
    <p>The term ‘mynhsdata.uk’ or ‘us’ or ‘we’ refers to the owner of the website. mynhsdata.uk is developed
      and operated by the Medical Devices Unit, NHS Greater Glasgow and Clyde. NHS Greater Glasgow and Clyde is
      registered at JB Russel House, Gartnavel Royal Hospital, Great Western Road, Glasgow, G12 0XH.</p>
    <h1>Acceptance of Terms</h1>
    <p>By using mynhsdata.uk, you agree to these Terms of Service. If you do not agree, please do not use our site.</p>
    <h1>Modification of Terms</h1>
    <p>We reserve the right to change these terms at any time. Changes will be posted on this page, and it is your
      responsibility to review them periodically.</p>
    <h1>User Responsibilities</h1>
    <p>Users must use the website in compliance with all applicable UK laws and regulations.</p>
    <ul>
      <li>Users are responsible for maintaining the confidentiality of their account information, including passwords,
        and for all activities that occur under their account.</li>
      <li>Users must not attempt to gain unauthorized access to any portion or feature of the website, or any other
        systems or networks connected to the website or to any server, by hacking, password mining, or any other
        illegitimate means.</li>
    </ul>

    <h1>Privacy Policy</h1>
    <p>Our privacy policy can be found here -
      <a href="https://www.nhsggc.scot/patient-visitor-faqs/data-protection-privacy/"
        rel="external nofollow noopener noreferrer"
        target="_blank">https://www.nhsggc.scot/patient-visitor-faqs/data-protection-privacy/</a></p>

    <h1>Third-Party Links and Services</h1>
    <p>We are not responsible for the content or services provided by third-party websites linked on our site.</p>

    <h1>Disclaimers and Limitation of Liability</h1>
    <p>mynhsdata.uk is provided "as is" without warranties of any kind. We are not liable for any damages arising
      from the use of our website.</p>

    <h1>Termination of Use</h1>
    <p>We reserve the right to terminate user access for violations of these terms.</p>

    <h1>Governing Law and Dispute Resolution</h1>
    <p>Your use of this website and any dispute arising out of such use of the website is subject to the laws of Scotland,
      Northern Ireland, England and Wales.</p>

    <h1>Contact Information</h1>
    <p>For questions or concerns, please contact us at <a href="mailto:ggc.mdu@nhs.scot">ggc.mdu@nhs.scot</a></p>

    <p>By using mynhsdata.uk, you acknowledge that you have read, understood, and agree to these Terms of Service.</p>

  </div>);
}
