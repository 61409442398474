import versionFile from './built_versioning_labels.json'

export interface VersioningLabels {
  version: {
    major: string,
    minor: string,
    patch: string
    versionString: string
  }

  labels: {
    releaseEnv: string
    preReleaseIdentifiers: string
    metaIdentifiers: string
    partialBuildLabel: string
    fullBuildLabel: string
  }

  git: {
    branch: string
    hash: string
  }
}

export function parseVersionFile(): VersioningLabels {
  // cast this to "any" because most of the values are only injected during CI build
  const vf = versionFile as any;
  return {
    version: {
      major: versionFile.version_major,
      minor: versionFile.version_minor,
      patch: versionFile.version_patch,
      versionString: vf.version_string || ''
    },

    labels: {
      releaseEnv: vf.release_env || '',
      preReleaseIdentifiers: vf.pre_release_identifiers || '',
      metaIdentifiers: vf.meta_identifiers || '',
      partialBuildLabel: vf.partial_build_label || '',
      fullBuildLabel: vf.full_build_label || ''
    },

    git: {
      branch: vf.git_branch || '',
      hash: vf.git_hash || ''
    }
  }
}

