import React from 'react';
import config from "../../config";
import "./Footer.scss";

export default function PageFooter() {
  return (
    <div className={"footer"} id={"page-footer"}>
      <a className={"footer-link"} href="mailto:ggc.mynhsdata@ggc.scot.nhs.uk">Feedback</a>
      <a className={"footer-link"} href="https://www.nhsggc.scot/patient-visitor-faqs/data-protection-privacy/">Privacy</a>
      <a className={"footer-link"} href="/terms">Terms</a>
      <hr />
      <div className={"footer-text-container"}>
        <span>
          © NHS Greater Glasgow & Clyde
        </span>
        <span>
          {config.version.labels.fullBuildLabel}
        </span>
      </div>
    </div>
  )
}
